import Axios, {AxiosError} from "axios";
import qs from "qs";
import {notification} from "antd";
import {Exception} from "../lib/exception";
import {store} from "../store";
import {ReduxActionType} from "../constants/redux-action-type";

export const request = Axios.create({
    baseURL: "/web",
    transformRequest: [data => {
        if (data instanceof FormData || Object.prototype.toString.call(data) === "[object FormData]") {
            return data;
        }

        return qs.stringify(data, {
            allowDots: true
        });
    }],
    headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/x-www-form-urlencoded",
        "token": localStorage.getItem("token"),
    }
});


request.interceptors.request.use(config => {
    return config;
});


request.interceptors.response.use(response => {
    if (response.data && response.data.code) {
        notification.error({
            message: "系统提示",
            description: response.data.msg || "请求数据失败，请刷新重试！"
        });

        throw new Exception("请求数据失败，请刷新重试！");
    }
    return response;
}, (err: AxiosError) => {
    if (err.isAxiosError && err.response) {
        if (err.response.status >= 400) {
            switch (err.response.status) {
                case 401:
                    store.dispatch({
                        type: ReduxActionType.LOGIN,
                        token: null,
                        user: null,
                    });
                    if (localStorage.getItem(ReduxActionType.LOGIN.toString())) {
                        notification.error({
                            message: "系统提示",
                            description: "登录过期，请重新登录"
                        });
                    }
                    break;
                default:
                    notification.error({
                        message: "系统提示",
                        description: err.message || err.response.data.msg
                    });
            }
        }
    }
    return Promise.reject(err);
});
