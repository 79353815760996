export enum LoggerLevel {
    error,
    info,
    log,
}

export class Logger {
    static level: LoggerLevel = LoggerLevel.error;
    static isDevMode: boolean = process.env.NODE_ENV === "development";

    static log(...args: any[]) {
        if (this.isDevMode && Logger.level <= LoggerLevel.log) {
            console.log(...args.map(item => {
                if (typeof item === "object" && item !== null) {
                    return JSON.stringify(item, null, 2);
                }
                return item;
            }));
        }
    }

    static info(...args: any[]) {
        if (this.isDevMode && Logger.level <= LoggerLevel.info) {
            console.info(...args.map(item => {
                if (typeof item === "object" && item !== null) {
                    return JSON.stringify(item, null, 2);
                }
                return item;
            }));
        }
    }

    static error(...args: any[]) {
        if (this.isDevMode && Logger.level <= LoggerLevel.error) {
            console.error(...args.map(item => {
                if (typeof item === "object" && item !== null) {
                    return JSON.stringify(item, null, 2);
                }
                return item;
            }));
        }
    }
}
