import { DataNode } from "antd/lib/tree";
import { TreeDataNode } from "../components/base/tree-select";

export class MenuUtil {
  static mapToTreeData(
    menus: IMenu[],
    pid: number,
    hasRoot: boolean = true
  ): any[] {
    const list: IMenu[] = [];
    const reduceList: IMenu[] = [];

    menus.forEach((item) => {
      if (item.pid === pid) {
        list.push(item);
      } else {
        reduceList.push(item);
      }
    });

    if (pid === 0 && hasRoot) {
      return [
        {
          value: 0,
          title: "顶级菜单",
          children: list
            .sort((a, b) => a.sort - b.sort)
            .map((item) => ({
              value: item.id,
              title: item.name,
              sort: item.sort,
              children: MenuUtil.mapToTreeData(reduceList, item.id),
              originData: item
            }))
            .sort((a, b) => {
              return a.sort - b.sort;
            })
        }
      ];
    }

    return list
      .sort((a, b) => a.sort - b.sort)
      .map((item) => ({
        value: item.id,
        title: item.name,
        children: MenuUtil.mapToTreeData(reduceList, item.id),
        sort: item.sort,
        originData: item
      }));
  }

  static mapToTree(
    menus: IMenu[],
    pid: number,
    parent?: TreeDataNode<number, IMenu>
  ): TreeDataNode<number, IMenu>[] {
    const list: IMenu[] = [];
    const reduceList: IMenu[] = [];

    menus.forEach((item) => {
      if (item.pid === pid) {
        list.push(item);
      } else {
        reduceList.push(item);
      }
    });

    return list
      .sort((a, b) => a.sort - b.sort)
      .map((item) => {
        const value: TreeDataNode<number, IMenu> = {
          key: item.id,
          title: item.name,
          originData: item,
          parent
        };
        value.children = MenuUtil.mapToTree(reduceList, item.id, value);
        return value;
      });
  }
  static menuVoToList(
    menuVo: MenuVo[],
    list: Omit<MenuVo, "childMenuVoList">[] = []
  ) {
    menuVo.forEach((item) => {
      const { childMenuVoList, ...rest } = item;
      list.push(rest);
      if (Array.isArray(childMenuVoList)) {
        MenuUtil.menuVoToList(childMenuVoList, list);
      }
    });
    return list;
  }
}
