import {Route as RouteInfo} from "../../routes";
import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {RouteContext} from "../../context";
import loadable from "@loadable/component";
import {Spin} from "antd";


export function MapRoute(props: MapRouteProps) {
    const {routes} = props.route;
    const defaultRoute = routes && routes[0];
    if (routes) {
        return (
            <Switch>
                {routes.map(_route => {
                    const {component, redirect, path} = _route;
                    const isExact = !Array.isArray(_route.routes);
                    if (component) {
                        const LoadableComponent = loadable(component, {
                            fallback: (
                                <div style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <Spin/>
                                </div>
                            )
                        });
                        const children = (
                            <RouteContext.Provider value={{route: _route}}>
                                <LoadableComponent {...props} />
                            </RouteContext.Provider>
                        );
                        return (
                            <Route
                                exact={isExact}
                                key={path}
                                path={path}
                                children={children}
                            />
                        );
                    } else if (redirect) {
                        return (
                            <Redirect
                                exact
                                key={path}
                                path={path}
                                to={redirect}
                            />
                        );
                    } else {
                        return null;
                    }
                })}

                {/*默认页面*/}
                {defaultRoute && defaultRoute.path && <Redirect to={defaultRoute.path}/>}
            </Switch>
        );
    }
    return null;
}

export interface MapRouteProps {
    route: RouteInfo;
}
