import { Layout } from "antd";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { MapRoute } from "../src/components/map-route";
import { ISystemState } from "./reducers/system";
import { Route, routes } from "./routes";
import { IStoreState } from "./store";
import { createRoute } from "./utils";
import { MenuUtil } from "./utils/menu";



function App() {
  const { user } = useSelector<IStoreState, ISystemState>(
    (state) => state.system
  );
  const route: Route = useMemo(() => {
    const menuVoToList = user ? MenuUtil.menuVoToList(user.menuVoList) : [];
    return createRoute(routes, menuVoToList);
  }, [user]);

  return (
    <Layout className="app">
      <MapRoute route={route} />
    </Layout>
  );
}

export default App;
