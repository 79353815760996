export class RandomUtil {
    /**
     * 创建随机byte值组
     * @param length
     */
    static createRandomBytes(length: number): number[] {
        const bytes: number[] = [];
        for (let i = 0; i < length; i++) {
            // create number as range 0-255
            bytes.push(Math.floor(Math.random() * 256));
        }
        return bytes;
    }
    /**
     * byte转hex
     * @param bytes
     */
    static byteToHex(bytes: number[]): string {
        return bytes.map(item => {
            const str = item.toString(16);
            if (str.length < 2) {
                return `0${str}`;
            }
            return str;
        }).join("");
    }

    /**
     * 创建随机key
     * @param length byte长度
     */
    static createRandomKey(length: number = 5) {
        return RandomUtil.byteToHex(RandomUtil.createRandomBytes(length));
    }
}
