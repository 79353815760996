import {Action} from "redux";

import {ReduxActionType} from "../../constants/redux-action-type";
import {request} from "../../utils/request";

const defaultUser = localStorage.getItem("user_info");

const INIT_STATE: ISystemState = {
    token: localStorage.getItem(ReduxActionType.LOGIN.toString()),
    user: defaultUser ? JSON.parse(defaultUser) : null,
};

request.defaults.headers["token"] = INIT_STATE.token;

export function systemReducer(state: ISystemState = INIT_STATE, action: Actions) {
    switch (action.type) {
        case ReduxActionType.LOGIN:
            if (action.token) {
                localStorage.setItem(action.type.toString(), action.token);
                localStorage.setItem("user_info", JSON.stringify(action.user));
                request.defaults.headers["token"] = action.token;
            } else {
                localStorage.removeItem(action.type.toString());
                localStorage.removeItem("user_info");
                request.defaults.headers["token"] = "";
            }
            return {
                ...state,
                token: action.token,
                user: action.user,
            };
        default:
            return {
                ...state,
            };
    }
}

type Actions = LoginAction;

export interface LoginAction extends Action<ReduxActionType.LOGIN> {
    token: NullLike;
    user: NullLike<UserLoginData>;
}

export interface ISystemState {
    token: NullLike;
    user: NullLike<UserLoginData>;
}
