import { MenuType } from "../constants/menu-type";
import { Route } from "../routes";

const PATH_REG = /^\//;

/**
 * 地柜路由
 * @param routes
 * @param parent
 * @param permissions
 */
function deepRoute(
  routes: Route[],
  parent: ParsedRoute,
  permissions: string[]
): ParsedRoute[] {
  return routes
    .filter((item) => {
      if (!item.permission) {
        return true;
      }
      if (Array.isArray(item.permission)) {
        return (
          item.permission.filter((permission) =>
            permissions.includes(permission)
          ).length === item.permission.length
        );
      }

      return permissions.includes(item.permission);
    })
    .map((item: ParsedRoute) => {
      const newItem: ParsedRoute = {
        parent,
        ...item,
      };

      // 判断路由的路径 如果不是以斜杠开头 则和父级拼接
      if (newItem.path && parent && !PATH_REG.test(newItem.path)) {
        newItem.path = parent.path + "/" + item.path;
      }

      // 判断路由重定向的路径 如果不是以斜杠开头 则和父级拼接
      if (newItem.redirect && parent && !PATH_REG.test(newItem.redirect)) {
        newItem.redirect = parent.path + "/" + item.redirect;
      }

      if (newItem.routes) {
        newItem.routes = deepRoute(newItem.routes, newItem, permissions);
      }
      return newItem;
    });
}

/**
 * 创建路由
 * @param routes
 * @param menuVoList
 */
export function createRoute(
  routes: Route[],
  menuVoList: Array<Omit<MenuVo, "childMenuVoList">>
): ParsedRoute {
  const route: ParsedRoute = {
    id: 100000,
    path: "/",
    type: MenuType.Directory,
  };
  route.routes = deepRoute(
    routes,
    route,
    menuVoList.map((item) => item.beforeUrl)
  );
  return route;
}
export interface ParsedRoute extends Omit<Route, "routes"> {
  parent?: ParsedRoute | Route;
  routes?: ParsedRoute[];
  search?: string;
}
