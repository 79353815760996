import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import pack from "../package.json";
import App from "./App";
import "./index.less";
import reportWebVitals from "./reportWebVitals";
import "@ant-design/pro-table/dist/table.css";
import { store } from "./store";

console.log("====================");
console.log("version", pack.version);
console.log("====================");

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN}>
      <BrowserRouter forceRefresh={false}>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
