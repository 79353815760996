import {
  LockOutlined,
  MenuOutlined,
  MoneyCollectOutlined,
  SettingOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import React, { ComponentType, ReactNode } from "react";
import { MenuType } from "../constants/menu-type";

export const AdminRoutes: Route[] = [
  {
    id: 12010000,
    type: MenuType.Directory,
    name: "客户管理",
    path: "customer",
    permission: "company:view",
    icon: <UsergroupAddOutlined />,
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12010100,
        type: MenuType.Page,
        name: "客户列表",
        path: "index.html",
        component: () => import("../pages/admin/customer/index/index"),
      },
      {
        id: 12010200,
        type: MenuType.Page,
        name: "客户编辑",
        hideMenu: true,
        path: "edit.html",
        component: () => import("../pages/admin/customer/edit"),
      },
      {
        id: 12010300,
        type: MenuType.Page,
        name: "缴费记录",
        hideMenu: true,
        path: "pay-record.html",
        component: () => import("../pages/admin/customer/pay-record"),
      },
    ],
  },
  {
    id: 12030000,
    type: MenuType.Directory,
    name: "用户管理",
    path: "user",
    permission: "user:view",
    icon: <TeamOutlined />,
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12030100,
        type: MenuType.Page,
        name: "用户列表",
        path: "index.html",
        component: () => import("../pages/admin/user/index/index"),
      },
      {
        id: 12030200,
        type: MenuType.Page,
        hideMenu: true,
        name: "用户编辑",
        path: "edit.html",
        component: () => import("../pages/admin/user/edit/index"),
      },
    ],
  },
  {
    id: 12040000,
    type: MenuType.Directory,
    name: "菜单设置",
    path: "menu",
    permission: "system:menu:view",
    icon: <MenuOutlined />,
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12040100,
        type: MenuType.Page,
        name: "SAAS菜单管理",
        path: "saas.html",
        component: () => import("../pages/admin/menu/saas"),
      },
      {
        id: 12040200,
        type: MenuType.Page,
        name: "客户端菜单管理",
        path: "user.html",
        component: () => import("../pages/admin/menu/user"),
      },
    ],
  },
  {
    id: 12050000,
    type: MenuType.Directory,
    name: "角色管理",
    path: "role",
    permission: "role:view",
    icon: <LockOutlined />,
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12050100,
        type: MenuType.Page,
        name: "角色列表",
        path: "index.html",
        component: () => import("../pages/admin/role/index"),
      },
      {
        id: 12050200,
        type: MenuType.Page,
        name: "角色设置",
        hideMenu: true,
        path: "setting.html",
        component: () => import("../pages/admin/role/setting"),
      },
    ],
  },
  {
    id: 12060000,
    type: MenuType.Directory,
    name: "字典管理",
    path: "dictionary",
    permission: "dictionary:management",

    icon: <SettingOutlined />,
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12060100,
        type: MenuType.Page,
        name: "字典管理",
        path: "index.html",
        component: () => import("../pages/admin/dictionary/index"),
      },
    ],
  },
  {
    id: 12080000,
    type: MenuType.Directory,
    name: "小程序管理",
    icon: <MoneyCollectOutlined />,
    permission: "applet:view",
    path: "applet",
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12080100,
        type: MenuType.Page,
        name: "小程序",
        path: "index.html",
        component: () => import("../pages/admin/applet/index"),
      },
      {
        id: 12080200,
        type: MenuType.Page,
        name: "小程序草稿",
        path: "draft.html",
        component: () => import("../pages/admin/applet/draft/index"),
      },
      {
        id: 12080300,
        type: MenuType.Page,
        name: "小程序模版库",
        path: "template.html",
        component: () => import("../pages/admin/applet/template/index"),
      },
    ],
  },
  {
    id: 12070000,
    type: MenuType.Directory,
    name: "财务管理",
    path: "finance",
    permission: "financial:control",
    icon: <MoneyCollectOutlined />,
    component: () => import("../layout/route-layout"),
    routes: [
      {
        id: 12070100,
        type: MenuType.Page,
        name: "财务管理",
        path: "index.html",
        component: () => import("../pages/admin/finance/index"),
      },
      {
        id: 12070200,
        type: MenuType.Page,
        name: "财务新增",
        hideMenu: true,
        path: "insert.html",
        component: () => import("../pages/admin/finance/form"),
      },
      {
        id: 12070300,
        type: MenuType.Page,
        name: "财务修改",
        hideMenu: true,
        path: "update.html",
        component: () => import("../pages/admin/finance/form"),
      },
    ],
  },
];

export const routes: Route[] = [
  {
    id: 11000000,
    path: "/",
    redirect: "/admin",
    type: MenuType.Page,
  },
  {
    id: 12000000,
    path: "/admin",
    name: "首页",
    type: MenuType.Directory,
    component: () => import("../layout/admin/logged-layout"),
    routes: AdminRoutes,
  },
  {
    id: 13000000,
    name: "登录页面",
    path: "/login.html",
    type: MenuType.Page,
    component: () => import("../pages/login"),
  },
  {
    id: 14000000,
    path: "/404.html",
    name: "404页面",
    type: MenuType.Page,
    component: () => import("../layout/not-found-layout"),
  },
  {
    id: 15000000,
    path: "*",
    type: MenuType.Page,
    redirect: "/404.html",
  },
];

export interface Route {
  id: number;
  hideMenu?: boolean;
  icon?: ReactNode;
  permission?: string[] | string;
  path?: string;
  name?: string;
  routes?: Route[];
  component?: () => Promise<{ default: ComponentType }>;
  redirect?: string;
  type: MenuType;
}
